.readDetailsImgContainer {
  padding: 30px;
  margin-bottom: 30px;
}

.readDetailsImgContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ArticleTitle {
  font-size: 34px;
  font-weight: 700;
  color: #0b132b;
  margin-top: 20px;
}

.paraSection {
  margin: 10px 0px;
}

.paraSection p {
  color: #676c7b;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0;
}

.paraSection ul li {
  color: #676c7b !important;
  list-style: disc !important;
  list-style-type: disc !important;
}
.paraSection ul li ul li {
  color: #676c7b !important;
  list-style: circle !important;
  list-style-type: circle !important;
}
