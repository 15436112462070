html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Playfair-Display-extrabold';
  src: url('assets/fonts/playfairdisplay/PlayfairDisplay-Black.woff2') format('woff2'),
      url('assets/fonts/playfairdisplay/PlayfairDisplay-Black.woff') format('woff'),
      url('assets/fonts/playfairdisplay/PlayfairDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('assets/fonts/poppins/Poppins-Regular.woff2') format('woff2'),
      url('assets/fonts/poppins/Poppins-Regular.woff') format('woff'),
      url('assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('assets/fonts/poppins/Poppins-Medium.woff2') format('woff2'),
      url('assets/fonts/poppins/Poppins-Medium.woff') format('woff'),
      url('assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-bold';
  src: url('assets/fonts/poppins/Poppins-Bold.woff2') format('woff2'),
      url('assets/fonts/poppins/Poppins-Bold.woff') format('woff'),
      url('assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('assets/fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
      url('assets/fonts/poppins/Poppins-SemiBold.woff') format('woff'),
      url('assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


h1 {font-family: 'Playfair-Display-extrabold';}

p{
  font-family: 'Poppins-Regular';
}

.PricingTableContainer .BlueButton:hover{
  background: #0590fa;
  border: 1px solid #7dc5fc;
}

.PricingTableContainer .GreemButton:hover {
  background: #06c02b;
  border: 1px solid #7ee793;
}

.PricingTableContainer .GreyButton:hover {
  background: #7785cd;
  border: 1px solid #b9c0e5;
}

.footerWebBottompowrdBy{
  display: none !important;
}

.linkFooterBototm {
  width: 45%;
  text-align: right;
}



.bookImg{
  width: 100% !important;
  height: 208px !important;

}

.MainBannerInner .BannerLinkButton {
  width: 152px;
  height: 50px;
  background: hsla(0,0%,100%,.2) 0 0 no-repeat padding-box!important;
  border: 1px solid #56b149 !important;
  background: #56b149 !important;
  border-radius: 4px;
  opacity: 1;
  margin-top: 32px!important;
  color: #fff!important;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.CheckOutmainContaier{
  padding: 125px;
}

.sectionSpace{
  margin-bottom: 170px;
}

.contactAlert {
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  left: 0;
}

/* .PricingTableContainer .priceSelectButton{
  display: none !important;
} */

.footerWebLinks a:hover{
  color: #56b149 !important;
}

.ModalClose {
  position: absolute;
  right: 15px;
  z-index: 999;
  top: 10px;
  cursor: pointer;
  color:#D2D3D8;
}
.ModalClose:hover {
  color:#56b149;
}

.ModalInnerContainer{
  position: relative;
}

.CellNumber{
  text-decoration: none;
  font-weight: 600;
  color: #2b2f39 !important;
}

