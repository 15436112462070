.InnerPageTitle {
  font-size: 48px;
  font-weight: 700;
  color: #0b132b;
}
.TosDate {
  color: #676c7b;
  font: 14px;
}

.PageContainer h2 {
  margin: 0;
  font-size: 24px;
  color: #1b2121;
}
.TosContainer p {
  margin: 12px 0;
  color: #676c7b;
  font-size: 20px;
  line-height: 36px;
}

.TosContainer ol li {
  color: #676c7b;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 24px;
}
.TosContainer ul li {
  color: #676c7b;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 24px;
}
