.GalleryContainer{
    background: #FCFCFC;
    padding: 30px;
    position: relative;
}

.GalleryContainer .GalleryTitle{
    font-size: 40px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    color: #0B132B;
    max-width: 460px;
    line-height: 46px;
    
}

.GalleryContainer  .GalleryDescription{
    font-size: 24px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400;
    color: #676C7B;
    max-width: 540px;
    line-height: 38px;
    margin-top: 12px;
}

.GalleryContainer .ViewMoreGaleryButton{
    background: #56B149 !important; 
    padding: 15px 24px !important;
    color: #fff !important;
    font-size:14px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    border: none !important;
    margin-top: 24px !important;
}

.GalleryContainer .ViewMoreGaleryButton:hover{
    background: #56B149 !important;
    color: #fff !important;
    border: none !important;
}

.GalleryContainer .ViewMoreGaleryButton:focus{
    background: #56B149 !important;
    color: #fff !important;
    border: none !important;
}


.imgsize_268{
    height: 168px;
}

.imgsize_130{
    height: 80px;
}

.imgsize_200{
    height: 100px;
}

.GelleryImgContaier img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: pointer;
}



