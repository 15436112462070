.ModalHeader {
  margin: 0 42px;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.MuiDialog-container .MuiPaper-root {
  padding: 48px;
  min-width: 630px;
  border-radius: 28px;
  overflow-y: clip;
}

.MuiTypography-root.MuiTypography-h6 {
  position: relative;
}

.MuiTypography-root.MuiTypography-h6 .MuiIconButton-root {
  position: absolute;
  top: -20px;
  right: -20px;
}

@media (max-width: 425px) {
  .MuiDialog-container .MuiPaper-root {
    padding: 48px;
    min-width: 0px;
    border-radius: 28px;
  }
  .ModalHeader {
    margin: 20px 22px 0px 22px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
  .MuiTypography-root.MuiTypography-h6 .MuiIconButton-root {
    position: absolute;
    top: -20px;
    right: -20px;
  }
}
